html, body {
  background-color: black;

  /*height: 5000px;*/
  height: 100%;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.logo {
  max-width: 200px;
}

#main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#main canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#main.hide canvas {
  bottom: -100vh;
}

#main.appear canvas {
  bottom: 0;
}

.stage {
  transform-origin: 0 0;
}

#stages { /* Container */
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* Mobile Safari optimizations */
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;  
}

#stages .layer {
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  will-change: transform;
  transform: translate3d(0, 0, 0);

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;  
  -webkit-perspective: 1000;

  -webkit-transform-style: preserve-3d;
}

#stages .entity {
  position: absolute;

  /* font */
  font-family: 'Press Start 2P';
  font-size: 45px;
  text-shadow: 2px 6px 1px rgba(0, 0, 0, 0.2);
  color: white;
}

@-webkit-keyframes MOVE-BG2 {
  from {
    -webkit-transform: translateX(0);
  }
  to { 
    -webkit-transform: translateX(-50%);
  }
}

@-webkit-keyframes MOVE-BG {
  0% { background-position: 0 0; }
  100% { background-position: -50% 0; }
}

.layer.clouds, .layer.clouds2 {
  /*animation-name: MOVE-BG;*/
  animation-timing-function: linear;
  animation-iteration-count: infinite;  
}

.layer.clouds {
  animation-duration: 400s;
}

.layer.clouds2 {
  animation-duration: 100s;
}

#debug {
  position: fixed;
  top: 0;
  left: 0;  
  color: white;
}

.character {
  width: 300px;
  height: 300px;
  bottom: 0;
  left: 0;

  will-change: transform;
  transform: translate3d(0, 0, 0);
}

#progress {
  position: fixed;
  top: 0;
  left: 0;

  width: 0%;
  height: 3px;
  background-color: #F2A94A;

  /*transition: width 0.2s ease-in-out;*/
}

.portal {
  position: absolute;
}

/*
body.debug .portal {
  background-color: green;
}

body.debug .character {
  border: 1px solid red;
}*/

body.debug .entity {
  border: 1px solid red;
}

.snow {
  animation-name: SKY-BG;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 100s;

  background-repeat: repeat !important;
}

@-webkit-keyframes SKY-BG {
  0% { background-position: 0px 0px; }
  10% { background-position: -4% 250px; }
  20% { background-position: 4% 500px; }
  30% { background-position: -4% 750px; }
  40% { background-position: 4% 1000px; }
  50% { background-position: -4% 1250px; }
  60% { background-position: 4% 1500px; }
  70% { background-position: -4% 1750px; }
  80% { background-position: 4% 2000px; }
  90% { background-position: -4% 2250px; }
  100% { background-position: 4% 2500px; }
}


/**
* https://codepen.io/Ioana/pen/rNZOYw
*/

#scroll-animation {
  position: fixed;
  z-index: 2222;
  zoom: 2;
  top: 25px;
  right: 25px;

  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

#scroll-animation span
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 6px;
}

#scroll-animation .unu
{
  margin-top: 6px;
}

#scroll-animation .unu, #scroll-animation .doi, #scroll-animation .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
}

#scroll-animation .unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
}

#scroll-animation .doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}

#scroll-animation .trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
}




#scroll-animation .mouse
{
height: 21px;
width: 14px;
  border-radius: 10px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

#scroll-animation .wheel
{
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;
}

#scroll-animation .wheel
{
  -webkit-animation: mouse-wheel 1.2s ease infinite;
  -moz-animation: mouse-wheel 1.2s ease infinite;
}

@-webkit-keyframes mouse-wheel
{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel
{
  0% { top: 1px; }
  50% { top: 2px; }
  100% { top: 3px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

/*
 * Loading animation
 */

#loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 2223;

  background-color: rgba(0,0,0,0.5);

  transition: opacity 0.2s ease-in-out, transform 1s ease-in-out;

  background: url('../assets/backgrounds/loading.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#loading-container h3 {
  font-family: 'Press Start 2P';
  margin-left: 22px;
  font-size: 11px;
}

#loading-container #loading-animation {
  width: 30px;
}

/*
#loading-container #nbn_letters .nbn_letter {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

#loading-container #nbn_letters {
  position: absolute;
  width: 180px;
  height: 90px;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -90px;
}
*/


/*#loading-animation {
  position: absolute;
  zoom: 0.7;
  top: 50%;
  left: 50%;

  width: 320px;
  height: 320px;

  margin-top: calc(-320px/2);
  margin-left: calc(-320px/2);

  transition: opacity 0.3s ease-in-out;
  opacity: 0;  
}

#loading-animation svg#gauge {
  margin: auto;
  display: block;

  height: 320px;
  width: 320px;
}

#loading-animation svg#gauge path {
  stroke-linecap: round;
  stroke-width: 16;
}

#loading-animation svg#gauge path.grey {
  stroke: rgba(255, 255, 255, 0.1);
}

#loading-animation svg#gauge path.orange {
  stroke: #F2A94A;
}*/

#social-join {
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

  z-index: 2222;
  /*display: none;*/

  position: fixed;
  background: white;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  text-align: center;
  background: #222;

  max-height: 100%;
  overflow-y: scroll;  

  padding-bottom: 20px;
}

#social-join img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

#social-join > div.content {
  padding: 15px;
  max-width: 800px;
  margin: 0 auto;
}

#social-join .row {
  width: 100%;
  margin-top: 30px;
}

#social-join h1 {
  background-color: #5390d9;
  background-image: linear-gradient(45deg, #C86F2D, #F2A94A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-shadow: 0px 0px 1px #F2A94A;
}

#social-join .row video {
  width: 100%;
}

#social-join .row #discord-image {
  width: 200px;
  transition: transform 0.2s ease-in-out;
}

#social-join .row #discord-image:hover {
  transform: scale(1.05);
}

#social-join .row #crown {
  margin-top:  30px;
  width: 70px;
}

#social-join .row.cc {
  margin: 15px 0;
  color: gray;
  font-size: 14px;
  font-family: 'Press Start 2P';
}

#social-join h1 {
  font-size: 22px;
  font-family: 'Press Start 2P';
}

#social-join #back-to-game {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  background: white;

  padding: 5px;
  border-bottom-right-radius: 4px;

  font-size: 10px;
  font-family: 'Press Start 2P';
}

.ethereum-wallets-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 88; /* wallet-connect: 89 */

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}

#ethereum-wallets {
  max-height: calc(100vh - 40px);
  background-color: #222222;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #323232;
  overflow: auto;
}

#ethereum-wallets .entry {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.0);
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
}

#ethereum-wallets .entry img.icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

#ethereum-wallets .entry div.title {
  margin-left: 12px;
  font-family: 'Press Start 2P';
  font-size: 11px;
}


#ethereum-wallets .entry div.description {
  margin-left: 0;
  margin-top: 12px;
  font-family: 'Press Start 2P';
  line-height: 16px;
  font-size: 8px;
  color: rgba(255, 255, 255, 0.7);
}

#ethereum-wallets .entry img.icon, #ethereum-wallets .entry div.title {
  display: inline-block;
  vertical-align: middle;
}

#ethereum-wallets .entry:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#ethereum-wallets .entry .title {
  color: white;
}

#ethereum-wallets .entry.loading {
  opacity: 0.4;
  pointer-events: none;
}

#ethereum-wallets.loading {
  animation: opacity-animation 2s infinite;
  pointer-events: none;
}

@keyframes opacity-animation {
  from {opacity: 0.4;}
  to {opacity: 0.8;}
}

#backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: none;

  /*display: flex;*/
  justify-content: center;
  align-items: center;
}

#backdrop h3 {
  font-family:  'Press Start 2P';
  font-size: 12px;
  color: white;
}


