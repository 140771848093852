html, body {
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  background-color: #000;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.logo {
  max-width: 200px;
}

#main {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#main canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#main.hide canvas {
  bottom: -100vh;
}

#main.appear canvas {
  bottom: 0;
}

.stage {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

#stages {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#stages .layer {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#stages .entity {
  text-shadow: 2px 6px 1px rgba(0, 0, 0, .2);
  color: #fff;
  font-family: "Press Start 2P";
  font-size: 45px;
  position: absolute;
}

@-webkit-keyframes MOVE-BG2 {
  from {
    -webkit-transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-50%);
  }
}

@-webkit-keyframes MOVE-BG {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.layer.clouds, .layer.clouds2 {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.layer.clouds {
  -webkit-animation-duration: 400s;
  animation-duration: 400s;
}

.layer.clouds2 {
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
}

#debug {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
}

.character {
  will-change: transform;
  width: 300px;
  height: 300px;
  bottom: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#progress {
  background-color: #f2a94a;
  width: 0%;
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
}

.portal {
  position: absolute;
}

body.debug .entity {
  border: 1px solid red;
}

.snow {
  -webkit-animation-name: SKY-BG;
  animation-name: SKY-BG;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: repeat !important;
}

@-webkit-keyframes SKY-BG {
  0% {
    background-position: 0 0;
  }

  10% {
    background-position: -4% 250px;
  }

  20% {
    background-position: 4% 500px;
  }

  30% {
    background-position: -4% 750px;
  }

  40% {
    background-position: 4% 1000px;
  }

  50% {
    background-position: -4% 1250px;
  }

  60% {
    background-position: 4% 1500px;
  }

  70% {
    background-position: -4% 1750px;
  }

  80% {
    background-position: 4% 2000px;
  }

  90% {
    background-position: -4% 2250px;
  }

  100% {
    background-position: 4% 2500px;
  }
}

#scroll-animation {
  z-index: 2222;
  zoom: 2;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 25px;
  right: 25px;
}

#scroll-animation span {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 5px;
  height: 5px;
  margin: 0 0 3px 6px;
  display: block;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#scroll-animation .unu {
  margin-top: 6px;
}

#scroll-animation .unu, #scroll-animation .doi, #scroll-animation .trei {
  -webkit-animation: 1s infinite mouse-scroll;
  -moz-animation: 1s infinite mouse-scroll;
}

#scroll-animation .unu {
  -webkit-animation-direction: alternate;
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
}

#scroll-animation .doi {
  -webkit-animation-direction: alternate;
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
}

#scroll-animation .trei {
  -webkit-animation-direction: alternate;
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
}

#scroll-animation .mouse {
  border: 2px solid #fff;
  border-radius: 10px;
  width: 14px;
  height: 21px;
  top: 170px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

#scroll-animation .wheel {
  background: #fff;
  width: 2px;
  height: 5px;
  margin: 5px auto;
  -webkit-animation: 1.2s infinite mouse-wheel;
  -moz-animation: 1.2s infinite mouse-wheel;
  display: block;
  position: relative;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }

  50% {
    top: 2px;
  }

  100% {
    top: 3px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

#loading-container {
  z-index: 2223;
  background: #fff url("loading.ae1cbf9c.jpg") center / cover no-repeat;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .2s ease-in-out, -webkit-transform 1s ease-in-out, -ms-transform 1s ease-in-out, transform 1s ease-in-out;
  transition: opacity .2s ease-in-out, -webkit-transform 1s ease-in-out, -ms-transform 1s ease-in-out, transform 1s ease-in-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#loading-container h3 {
  margin-left: 22px;
  font-family: "Press Start 2P";
  font-size: 11px;
}

#loading-container #loading-animation {
  width: 30px;
}

#social-join {
  z-index: 2222;
  text-align: center;
  background: #222;
  max-height: 100%;
  padding-bottom: 20px;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

#social-join img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

#social-join > div.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
}

#social-join .row {
  width: 100%;
  margin-top: 30px;
}

#social-join h1 {
  background-color: #5390d9;
  background-image: -webkit-linear-gradient(45deg, #c86f2d, #f2a94a);
  background-image: linear-gradient(45deg, #c86f2d, #f2a94a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-shadow: 0 0 1px #f2a94a;
}

#social-join .row video {
  width: 100%;
}

#social-join .row #discord-image {
  width: 200px;
  -webkit-transition: -webkit-transform .2s ease-in-out, -ms-transform .2s ease-in-out, transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out, -ms-transform .2s ease-in-out, transform .2s ease-in-out;
}

#social-join .row #discord-image:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

#social-join .row #crown {
  width: 70px;
  margin-top: 30px;
}

#social-join .row.cc {
  color: gray;
  margin: 15px 0;
  font-family: "Press Start 2P";
  font-size: 14px;
}

#social-join h1 {
  font-family: "Press Start 2P";
  font-size: 22px;
}

#social-join #back-to-game {
  cursor: pointer;
  background: #fff;
  border-bottom-right-radius: 4px;
  padding: 5px;
  font-family: "Press Start 2P";
  font-size: 10px;
  position: fixed;
  top: 0;
  left: 0;
}

.ethereum-wallets-backdrop {
  z-index: 88;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ethereum-wallets {
  background-color: #222;
  border: 1px solid #323232;
  border-radius: 5px;
  width: 300px;
  max-height: calc(100vh - 40px);
  overflow: auto;
}

#ethereum-wallets .entry {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px;
  -webkit-transition: background-color .1s ease-in-out;
  transition: background-color .1s ease-in-out;
}

#ethereum-wallets .entry img.icon {
  object-fit: contain;
  width: 32px;
  height: 32px;
}

#ethereum-wallets .entry div.title {
  margin-left: 12px;
  font-family: "Press Start 2P";
  font-size: 11px;
}

#ethereum-wallets .entry div.description {
  color: rgba(255, 255, 255, .7);
  margin-top: 12px;
  margin-left: 0;
  font-family: "Press Start 2P";
  font-size: 8px;
  line-height: 16px;
}

#ethereum-wallets .entry img.icon, #ethereum-wallets .entry div.title {
  vertical-align: middle;
  display: inline-block;
}

#ethereum-wallets .entry:hover {
  background-color: rgba(255, 255, 255, .1);
}

#ethereum-wallets .entry .title {
  color: #fff;
}

#ethereum-wallets .entry.loading {
  opacity: .4;
  pointer-events: none;
}

#ethereum-wallets.loading {
  pointer-events: none;
  -webkit-animation: 2s infinite opacity-animation;
  animation: 2s infinite opacity-animation;
}

@-webkit-keyframes opacity-animation {
  from {
    opacity: .4;
  }

  to {
    opacity: .8;
  }
}

@keyframes opacity-animation {
  from {
    opacity: .4;
  }

  to {
    opacity: .8;
  }
}

#backdrop {
  z-index: 2;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#backdrop h3 {
  color: #fff;
  font-family: "Press Start 2P";
  font-size: 12px;
}

/*# sourceMappingURL=index.f679eaa2.css.map */
